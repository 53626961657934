<template>
  <div class="container-xl flex-container column-flex p-0 mt-5">
    <div class="text-center">
      <h1 id="section-title"> Terms of Service </h1>
      <p id="section-description"> Work in progress </p>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
#section-title {
  color: var(--clr-primary);
  font-size: 2.4rem;
  font-weight: 400;
}

#section-description{
  color: var(--clr-gray-3);
  margin-left: 2rem;
  margin-bottom: 3rem;
}
</style>
